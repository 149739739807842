import React, { useEffect } from "react"
import styled from "styled-components"
import { RoughNotation } from "react-rough-notation"

import SunAnim from "../../../assets/svg/sun-anim.svg"
import MoonAnim from "../../../assets/svg/moon-anim.svg"
import Rss from "../../../assets/svg/rss.svg"

const Title = styled.span`
  color: var(--text);
`

const SubTitle = styled.span`
  font-family: "Allura" !important;
  margin-top: -13px;
`

const HeaderLeft = styled.div`
  flex: 1 1 0%;
  display: flex;
  -webkit-box-align: baseline;
  align-items: baseline;
  z-index: 50;
`

const HeaderRight = styled.div`
  & .rss {
    fill: var(--text);
  }
`

const Link = styled.a`
  color: var(--text);
  transition: transform 250ms, color 250ms ease;
  &:hover {
    font-weight: 600;
    transform: translateY(-5px);
    color: var(--blue);
  }
`

export default function HeaderBar(props) {
  const [theme, updateTheme] = React.useState(null)

  useEffect(() => {
    updateTheme(props.theme)
  }, [props.theme])

  return (
    <div className="pl-4 pr-4 md:pl-8 md:pr-8 z-20">
      {" "}
      {/* header wrapper */}
      <header className="h-14 flex justify-between items-center p-0">
        <HeaderLeft>
          <a className="mr-8" href="/#top">
            <Title className="text-4xl font-semibold block tracking-tighter">
              deconta
            </Title>
            <SubTitle
              className="
                text-right ml-20 block text-xl
                font-semibold text-blue-600
                dark:text-blue-400
              "
            >
              pure air
            </SubTitle>
          </a>
          <nav className="hidden md:flex">
            <ul className="flex list-none">
              {/* <li className="m-2">
                                <div className="relative flex">
                                    <Link className="relative p-2 font-semibold text-lg" href="/apis">
                                        Latest
                                    </Link>
                                </div>
                            </li> */}
              {/* <li className="m-2">
                                <div className="relative flex">
                                    <Link className="relative p-2 font-semibold text-lg" href="/apis">
                                        Pricing
                                    </Link>
                                </div>
                            </li> */}
              {/* <li className="m-2">
                                <div className="relative flex">
                                    <Link className="relative p-2 font-semibold text-lg" href="/apis">
                                        APIs
                                    </Link>
                                </div>
                            </li> */}
              <li className="m-2">
                <div className="relative flex">
                  <Link
                    className="relative p-2 font-semibold text-lg"
                    href="/docs#top"
                  >
                    <RoughNotation
                      type="underline"
                      show={props.page === "docs"}
                      color={
                        theme === "light"
                          ? "rgba(37, 99, 235)"
                          : "rgba(59, 130, 246)"
                      }
                    >
                      Docs
                    </RoughNotation>
                  </Link>
                </div>
              </li>
              <li className="m-2">
                <div className="relative flex">
                  <Link
                    className="relative p-2 font-semibold text-lg"
                    href="https://www.deconta-connect.com/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    deconta-connect
                  </Link>
                </div>
              </li>
            </ul>
          </nav>
        </HeaderLeft>
        <HeaderRight>
          <div className="hidden md:flex">
            <button
              aria-label="Dark Mode"
              className="z-50 w-10 h-8 relative rounded-md flex items-center"
              onClick={() =>
                props.toggleTheme(theme === "dark" ? "light" : "dark")
              }
            >
              {theme === "light" && (
                <SunAnim className="h-6 w-6 outline-none mx-auto" />
              )}
              {theme === "dark" && (
                <MoonAnim className="h-6 w-6 outline-none mx-auto" />
              )}
            </button>
            <div className="w-3" />
            <button
              aria-label="RSS"
              className="z-50 w-10 h-8 relative rounded-md flex items-center"
            >
              <Rss className="h-6 w-6 mx-auto rss" />
            </button>
            <div className="w-4" />
            <div className="h-8 z-30 items-center relative flex">
              <a
                href="https://www.deconta-connect.com/"
                rel="noreferrer"
                target="_blank"
                className="bg-blue-600 text-gray-50 items-center rounded-md font-medium text-sm p-1 pl-2 pr-2"
              >
                LOGIN
              </a>
            </div>
          </div>
        </HeaderRight>
      </header>
    </div>
  )
}
