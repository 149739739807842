import React from "react"
import styled from "styled-components"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import { Helmet } from "react-helmet"

import HeaderBar from "./UI/header/headerBar"
import HeaderDesc from "./UI/header/header-desc"
import HeaderPanels from "./UI/header/header-panels"

const HeaderImage = styled.div`
  background: ${props =>
    `url(https://sre-connect.firebaseapp.com/static/media/deconta_bg.a91ce359.png) ${
      props.width > 768 ? "40%" : "52%"
    } 20% !important`};
  background-repeat: no-repeat;
  background-blend-mode: color, normal;
`

const Footer = styled.footer`
  background: var(--bg-panel-header);
  & ul {
    color: var(--textSd);
  }
  & .title {
    color: var(--blue);
  }
`

const FooterLeft = styled.div`
  & h3 {
    color: var(--textSd);
  }
  & p {
    color: var(--textSd);
  }
`

const Title = styled.span`
  color: var(--text);
`

const SubTitle = styled.span`
  font-family: "Allura" !important;
  margin-top: -13px;
`
const FooterRight = styled.div``

export default function Layout({ page, children }) {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <div className="relative" id="top">
          <div className="wrapper max-w-5xl mx-auto">
            <Helmet
              htmlAttributes={{
                lang: "en",
              }}
            >
              <title>deconta-connect API docs</title>
              <meta
                name="description"
                content="deconta-connect application programming interface
                            documentation with application examples"
              />
              <meta charSet="utf-8" />
            </Helmet>
            <div className="header">
              <div
                className={`${
                  page != null && page === "index" ? "h-2 md:h-8" : "hidden"
                }`}
              />
              <HeaderBar theme={theme} toggleTheme={toggleTheme} page={page} />
              {page != null && page === "index" && (
                <>
                  <HeaderImage
                    className="h-96 absolute w-full left-0 top-0 z-10"
                    style={{ height: 500 }}
                  />
                  <div
                    className="header-blend h-96 absolute w-full left-0 top-0 z-20"
                    style={{ height: 500 }}
                  />
                  <HeaderDesc />
                  <HeaderPanels theme={theme} />
                </>
              )}
            </div>
            <div className="content">{children}</div>
          </div>
          <div>
            <Footer className="block p-0 mt-20 md:flex">
              <div className="w-full block md:flex max-w-5xl mx-auto">
                <FooterLeft className="pl-4 pr-4 text-center pt-10 md:pl-20 md:pr-20 md:text-left md:w-2/5">
                  <Title className="">
                    <a className="mr-8" href="#top">
                      <Title className="text-4xl font-semibold block tracking-tighter">
                        deconta
                      </Title>
                      <SubTitle
                        className="
                          ml-20 block text-xl
                          font-semibold text-blue-600
                          dark:text-blue-400
                        "
                      >
                        pure air
                      </SubTitle>
                    </a>
                  </Title>
                </FooterLeft>
                <FooterRight className="block text-center md:flex md:text-left md:w-3/5 justify-end">
                  <section className="pt-12 md:pb-20 md:pr-10">
                    <div className="relative md:pb-10">
                      <p className="text-xs uppercase pb-3 font-bold title tracking-tight">
                        Legal
                      </p>
                      <ul className="uppercase">
                        <li className="text-xs font-semibold pb-2">
                          <a
                            href="https://www.deconta.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Impressum
                          </a>
                        </li>
                        <li className="text-xs font-semibold pb-2">
                          <a
                            href="https://www.deconta-connect.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Privacy Policy
                          </a>
                        </li>
                      </ul>
                    </div>
                  </section>
                  <section className="pt-12 md:pb-20 md:pr-10">
                    <div className="relative pb-10">
                      <p className="text-xs uppercase pb-3 font-bold title tracking-tight">
                        Resources
                      </p>
                      <ul className="uppercase">
                        <li className="text-xs font-semibold pb-2">
                          <a
                            href="https://www.deconta.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            deconta GmbH
                          </a>
                        </li>
                        <li className="text-xs font-semibold pb-5">
                          <a
                            href="https://www.deconta-connect.com/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            deconta-connect
                          </a>
                        </li>

                        <li className="text-xs font-semibold pb-2">
                          <a href="/docs">Explore</a>
                        </li>
                        <li className="text-xs font-semibold pb-2">
                          <a href="/docs">Pricing</a>
                        </li>
                        <li className="text-xs font-semibold pb-2">
                          <a href="/docs">FAQ</a>
                        </li>
                        <li className="text-xs font-semibold pb-5">
                          <a href="/docs">Documentation</a>
                        </li>

                        <li className="text-xs font-semibold pb-2">
                          <a href="/docs">Status</a>
                        </li>
                        <li className="text-xs font-semibold pb-2">
                          <a href="/docs">Affiliates</a>
                        </li>
                      </ul>
                    </div>
                    <div className="relative">
                      <p className="text-xs uppercase pb-3 font-bold title tracking-tight">
                        Social
                      </p>
                      <ul className="uppercase">
                        <li className="text-xs font-semibold pb-2">
                          <a
                            href="https://www.youtube.com/channel/UCIX3dP_kF4oDi2NRRS-aWJQ"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Youtube
                          </a>
                        </li>
                        <li className="text-xs font-semibold pb-2">
                          <a
                            href="https://www.linkedin.com/in/deconta-gmbh-078583ba/?originalSubdomain=de"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Linkedin
                          </a>
                        </li>
                        <li className="text-xs font-semibold pb-2">
                          <a
                            href="https://www.facebook.com/deconta-GmbH-229044127220201/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Facebook
                          </a>
                        </li>
                      </ul>
                    </div>
                  </section>
                  <section className="mt-12 md:mb-5 md:border-l-2 md:border-gray-200 pl-5 pr-5">
                    <p className="text-xs uppercase pb-3 font-bold title tracking-tight">
                      Contact
                    </p>
                    <ul>
                      <li className="text-xs pb-5">
                        <p className="font-bold text-sm">HQ</p>
                        <p className="font-bold">deconta GmbH</p>
                        <p>Im Geer 20</p>
                        <p>46419 Isselburg - Germany</p>
                        <p>+49 (0) 28 74 91 56 0</p>
                        <p>info@deconta.com</p>
                      </li>
                      <li className="text-xs pb-5">
                        <p className="font-bold">deconta s.a.r.l.</p>
                        <p>15, rue des Couturièrs</p>
                        <p>67240 BISCHWILLER - France</p>
                        <p>+33 (0) 3 88 07 17 37</p>
                        <p>info@deconta.fr</p>
                      </li>
                      <li className="text-xs pb-5">
                        <p className="font-bold">deconta Gerätetechnik AG</p>
                        <p>Alte Aarburgerstrasse 46</p>
                        <p>4852 Rothrist - Switzerland</p>
                        <p>+41 (0) 62 752 46 00</p>
                        <p>info@deconta.ch</p>
                      </li>
                      <li className="text-xs pb-5">
                        <p className="font-bold">deconta s.r.l. a s.u.</p>
                        <p>Via Rizzotti 5</p>
                        <p>37064 Povegliano Veronese (VR) - Italy</p>
                        <p>+39 045 63 50 750</p>
                        <p>info@deconta.it</p>
                      </li>
                      <li className="text-xs pb-5">
                        <p className="font-bold">deconta SL</p>
                        <p>C/ Enric Prat de la Riba núm 2</p>
                        <p>08830 ST BOI DE LLOBREGAT (BCN) - Spain</p>
                        <p>+34 93 63 00 39 0</p>
                        <p>info@deconta.es</p>
                      </li>
                      <li className="text-xs pb-5">
                        <p className="font-bold">deconta Pty Ltd</p>
                        <p>Unit 3, 22-30 North View Drive</p>
                        <p>Sunshine West, VIC 3020 - Australia</p>
                        <p>+61 (0) 3 93 124565</p>
                        <p>info@deconta.com.au</p>
                      </li>
                      <li className="text-xs pb-5">
                        <p className="font-bold">deconta UK Ltd</p>
                        <p>Unit 3 - 4 Edtech Business Park</p>
                        <p>Locomotion Way</p>
                        <p>Camperdown Industrial Estate Killingworth</p>
                        <p>Tyne & Wear NE12 5UP - England</p>
                        <p>+44 (0) 191 268 9809</p>
                        <p>info@deconta.co.uk</p>
                      </li>
                    </ul>
                  </section>
                </FooterRight>
              </div>
            </Footer>
          </div>
        </div>
      )}
    </ThemeToggler>
  )
}
