import React from "react"
import styled from "styled-components"
import sal from "sal.js"

const Title = styled.article`
  width: 550px;
  & h3 {
    color: var(--text);
  }
  & p {
    color: var(--text);
  }
`

export default function HeaderDesc() {
  React.useEffect(() => {
    sal()
  }, [])

  return (
    <div className="flex pl-4 pr-4 pt-10 md:pl-16 md:pr-16 md:pt-20 items-center h-80 md:h-72">
      <Title className="z-30 w-96">
        <h3
          className="text-3xl md:text-3xl font-normal pb-3"
          data-sal-duration="500"
          data-sal="slide-up"
          data-sal-easing="ease"
        >
          deconta-connect Developer Hub
        </h3>
        <p
          className="text-base md:text-base font-medium pb-1"
          data-sal-duration="500"
          data-sal="slide-up"
          data-sal-easing="ease"
        >
          Build by Developers, for Developers
        </p>
        <p
          className="text-sm md:text-base pb-5"
          data-sal-duration="500"
          data-sal="slide-up"
          data-sal-easing="ease"
        >
          Start to develop your application in no time. We provide you with our
          Representational State Transfer Application Programming Interface to
          implement your application. Whether it is a private person or a large
          business application, or even Industry 4.0
        </p>
        <a
          href="https://www.deconta-connect.com/"
          target="_blank"
          rel="noreferrer"
          className="z-50 h-8 uppercase bg-blue-600 text-gray-50 rounded-md font-medium text-sm p-2"
          data-sal-duration="500"
          data-sal="slide-up"
          data-sal-easing="ease"
        >
          request api key
        </a>
      </Title>
    </div>
  )
}
