import React from "react"
import styled from "styled-components"
import sal from "sal.js"

import ArrowRight from "../../../assets/svg/arrow-right.svg"
import Cloud from "../../../assets/svg/cloud.svg"
import Docs from "../../../assets/svg/docs.svg"
import Api from "../../../assets/svg/api.svg"

const Panel = styled.div`
  background: var(--bg-panel-header);
  border-color: var(--blue);
  &:hover {
    & .arrow {
      transform: translateX(10px) scaleX(1.1);
      transition: transform 250ms;
      stroke: var(--blue);
    }
  }
  & .arrow {
    stroke: var(--text);
  }
  & svg {
    fill: var(--blue);
  }
  & h3 {
    color: var(--text);
  }
  & p {
    color: var(--text);
  }
`

export default function HeaderPanels(props) {
  React.useEffect(() => {
    sal()
  }, [])

  return (
    <div className="max-w-5xl flex mr-auto ml-auto pl-16 pr-16 pt-20 items-center justify-center">
      <a className="z-30" href="/explore">
        <Panel
          className="hidden w-64 h-28 p-2 border-l-4 cursor-pointer sm:flex"
          data-sal-duration="500"
          data-sal="slide-up"
          data-sal-delay="500"
          data-sal-easing="ease"
        >
          <div className="w-1/6">
            <Api className="w-7 h-7" fill="rgba(37, 99, 235)" />
          </div>
          <div className="w-5/6">
            <span className="inline-flex items-center pb-1">
              <h3 className="text-base font-medium block w-full">Explore</h3>
              <ArrowRight className="h-5 w-8 mt-1 arrow" />
            </span>
            <p className="block w-full text-sm">
              Explore our Application Programming Interface endpoints
            </p>
          </div>
        </Panel>
      </a>
      <div className="w-10" />
      <a className="z-30" href="/docs">
        <Panel
          className="w-64 h-28 p-2 border-l-4 cursor-pointer flex"
          data-sal-duration="500"
          data-sal="slide-up"
          data-sal-delay="600"
          data-sal-easing="ease"
        >
          <div className="w-1/6">
            <Docs className="w-7 h-7" fill="rgba(37, 99, 235)" />
          </div>
          <div className="w-5/6">
            <span className="inline-flex items-center pb-1">
              <h3 className="text-base font-medium block w-full">
                Documentation
              </h3>
              <ArrowRight className="h-5 w-8 mt-1 arrow" />
            </span>
            <p className="block w-full text-sm">
              See our documentation for a quick integration into your system
            </p>
          </div>
        </Panel>
      </a>
      <div className="w-10" />
      <a
        className="z-30"
        href="https://www.deconta-connect.com/"
        target="_blank"
        rel="noreferrer"
      >
        <Panel
          className="hidden w-64 h-28 p-2 border-l-4 cursor-pointer lg:flex"
          data-sal-duration="500"
          data-sal="slide-up"
          data-sal-delay="700"
          data-sal-easing="ease"
        >
          <div className="w-1/6 pt-0.5">
            <Cloud className="w-7 h-7" fill="rgba(37, 99, 235)" />
          </div>
          <div className="w-5/6">
            <span className="inline-flex items-center pb-1">
              <h3 className="text-base font-medium block w-full">
                deconta-connect
              </h3>
              <ArrowRight className="h-5 w-8 mt-1 arrow" />
            </span>
            <p className="block w-full text-sm">
              Register your device at deconta-connect
              to enjoy the full range of features
            </p>
          </div>
        </Panel>
      </a>
    </div>
  )
}
